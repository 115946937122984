import React, { useState } from 'react';
import { Routes, Route, Link } from "react-router-dom";

import "./bootstrap.min.css";
import './App.css';
import { AnimatePresence } from 'framer-motion';
import { slide as MenuSlide } from 'react-burger-menu';
import 'reactjs-bottom-navigation/dist/index.css';

import didayz_name from "./assets/didayz/didayz_icone_name.png";
import whitedidayz from "./assets/didayz/whitedidayz.png";

import NewPassword from "./components/newPassword.component";
import Home from "./components/home.component";
import Prices from "./components/prices.component";
import LegalNotice from "./components/legalNotice.component";
import ContactUS from "./components/contact-us.component";
import Guide from "./components/guide.component";

import AndroidRoundedIcon from '@mui/icons-material/AndroidRounded';

import { isFromPhone } from "./components/globalVariable.component";

function App() {

  const [heightToolbar, setHeightToolbar] = useState("calc(50px + 1vw)");
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  }

  window.addEventListener('scroll', (event) => {
    if(window.scrollY > 0){
      setHeightToolbar("calc(40px + 1vw)");
    }
    else
    {
      setHeightToolbar("calc(50px + 1vw)");
    }
  });

  return (
    <div style={{minHeight: "100vh"}}>
      <div style={{ minHeight: "100vh", display: "grid" }}> 
        <div className="toolbar">
          <Link id='linkHome' to="/home" style={{justifySelf: "flex-start"}}>
            <img alt="Icône" src={didayz_name} className="cursorPointer" style={{height: heightToolbar, justifySelf: "flex-start", paddingLeft: "calc(5px + 1vw)"}}></img>
          </Link>
          {isFromPhone ?
            <div style={{display: "grid", paddingRight: "calc(5px + 2vw)", alignItems: "center", alignSelf: "center", justifySelf: "flex-end", height: "fit-content"}}>    
              <MenuSlide isOpen={menuOpen} onStateChange={(state) => handleStateChange(state)} right pageWrapId={ "page-wrap" }>
                <label className="titleBurgerMenu">Produit</label>
                <a id="download" className="menu-item" onClick={() => setMenuOpen(false)} href="https://play.google.com/store/apps/details?id=fr.didayz.app" target="_blank">Télécharger l'app</a>
                <a id="guide" className="menu-item" onClick={() => setMenuOpen(false)} href="/guide">FAQ</a>
                <a id="prices" className="menu-item" onClick={() => setMenuOpen(false)} href="/prices">Tarifs</a>
                <label className="titleBurgerMenu" style={{marginTop: "30px"}}>Didayz</label>
                <a id="contact" className="menu-item" onClick={() => setMenuOpen(false)} href="/contactus">Contact</a>
                <a id="legalMentions" className="menu-item" onClick={() => setMenuOpen(false)} href="/mentionslegales">Mentions légales</a>
              </MenuSlide> 
            </div> :
            <div className="logbuttons">
              <button className="logbutton loginButton" onClick={() => window.open("https://play.google.com/store/apps/details?id=fr.didayz.app", '_blank')}>
                <AndroidRoundedIcon sx={{ color: "black" }} /> Télécharger sur Android
              </button>
            </div>      
          }
        </div>
        <div className="mainBody">
          <AnimatePresence>
            <Routes>
              <Route path="/newpwd/:idTmpToken" element={<NewPassword />} ></Route>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/prices" element={<Prices />} />
              <Route path="/mentionslegales" element={<LegalNotice />} />
              <Route path="/contactus" element={<ContactUS />} />
              <Route path="/guide" element={<Guide />} />
            </Routes>
          </AnimatePresence>
        </div>
      </div>
      {isFromPhone ?
        <div style={{ backgroundColor: "#000000", minHeight: "calc(50px + 5vw)", paddingTop: "calc(10px + 2vw)", paddingBottom: "calc(10px + 2vw)"}}>
        </div> :
        <div className="footer">
          <img alt="Didayz" src={whitedidayz} style={{height: "calc(30px + 5vw)"}}></img>
          <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", justifyItems: "flex-start", columnGap: "4vw"}}>
            <div className="footerLabels" style={{display: "grid", gridTemplateRows: "1fr 1fr 1fr 1fr"}}>
              <label style={{fontWeight: "bold"}}>Produit</label>
              <a href="https://play.google.com/store/apps/details?id=fr.didayz.app" target="_blank">Télécharger l'app</a>
              <a href="/guide">FAQ</a>
              <a href="/prices">Tarifs</a>
            </div>
            <div className="footerLabels" style={{display: "grid", gridTemplateRows: "1fr 1fr 1fr 1fr"}}>
              <label style={{fontWeight: "bold"}}>Didayz</label>
              <a href="/contactus">Contact</a>
              <a href="/mentionslegales">Mentions légales</a>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default App;
import React, { useEffect } from "react";
import Grid from '@material-ui/core/Grid';

import background_home from '../assets/background_home.png';
import feelings_didayz from '../assets/feelings_didayz.jpg';

import { isFromPhone } from "./globalVariable.component";

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {isFromPhone ?
        <Grid container style={{height: "calc(100vh - 30px - 6vw)", display: "grid", gridTemplateRows: "3fr 4fr"}}>
          <Grid item xs={12} style={{display: "grid", justifyContent: "center", paddingRight: "calc(30px + 2vw)", paddingLeft: "calc(30px + 2vw)", alignItems: "flex-end"}}>
            <img alt="Didayz" style={{width: "100%", maxHeight: "calc(100vh - 40px - 7vw)", justifySelf: "flex-end", marginBottom: "5vw"}} src={feelings_didayz}></img>
          </Grid>
          <Grid container item xs={12} className="prezHome" style={{ alignContent: "flex-start" }}>
            <Grid item xs={12} style={{ display: "grid", alignItems: "flex-end" }}>
              <label className="taglineHome">Rendre chaque jour unique !</label>
            </Grid>
            <Grid item xs={12}>
              <label className="descHome">Didayz vous permet d'offrir des surprises pour accompagner vos proches dans la dernière ligne droite avant une échéance.</label>
            </Grid>
          </Grid>
        </Grid> :
        <Grid container style={{height: "calc(100vh - 30px - 6vw)"}}>
          <Grid container item md={5} className="prezHome">
            <Grid item xs={12} style={{ display: "grid", alignItems: "flex-end" }}>
              <label className="taglineHome">Rendre chaque jour unique !</label>
            </Grid>
            <Grid item xs={12}>
              <label className="descHome">Didayz vous permet d'offrir des surprises pour accompagner vos proches dans la dernière ligne droite avant une échéance.</label>
            </Grid>
          </Grid>
          <Grid item md={7} style={{display: "grid", justifyContent: "center", paddingRight: "calc(30px + 2vw)", paddingLeft: "calc(30px + 2vw)", alignItems: "flex-end"}}>
            <img alt="Didayz" style={{width: "100%", maxHeight: "calc(100vh - 40px - 7vw)", justifySelf: "flex-end", marginBottom: "5vw"}} src={feelings_didayz}></img>
          </Grid>
        </Grid>
      }  
      <Grid container style={{ display: "grid", justifyContent: "center"}}>
        <img alt="Explication fonctionnelle Didayz" style={{maxHeight: "calc(100vh - 40px - 7vw)", maxWidth: "100%", justifySelf: "flex-end"}} src={background_home}></img>
      </Grid>     
    </div>
  );
}

export default Home